<template>
  <div class="releaseDesk">
    <el-form
      ref="releaseForm"
      :model="releaseForm"
      :rules="rules"
      class="releaseForm"
      label-width="auto"
    >
      <el-form-item :label="autoPrefix('title') + ''" prop="title">
        <el-input
          v-model="releaseForm.title"
          :placeholder="autoPrefix('title', 'pla')"
          maxlength="150"
          show-word-limit
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('content')" prop="content">
        <el-input
          v-model="releaseForm.content"
          class="contentInput"
          type="textarea"
          maxlength="1000"
          :rows="7"
          :placeholder="autoPrefix('content', 'pla')"
          show-word-limit
        />
      </el-form-item>
      <div class="countryContainer">
        <el-form-item
          class="country"
          :label="autoPrefix('country')"
          prop="country"
        >
          <el-select
            v-model="releaseForm.country"
            :placeholder="autoPrefix('country', 'pla')"
          >
            <el-option
              v-for="item in dictionaryList.content"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="releaseForm.country === 1"
          class="province"
          prop="province"
          label-width="0"
        >
          <el-select
            v-model="releaseForm.province"
            :placeholder="autoPrefix('province', 'pla')"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item :label="autoPrefix('businessTypeId')" prop="businessTypeId">
        <el-select
          v-model="releaseForm.businessTypeId"
          clearable
          multiple
          :placeholder="autoPrefix('businessTypeId', 'pla')"
        >
          <el-option
            v-for="item in businessTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="autoPrefix('contactPerson')" prop="contactPerson">
        <el-input
          v-model="releaseForm.contactPerson"
          maxlength="50"
          :placeholder="autoPrefix('contactPerson', 'pla')"
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('contactPhone')" prop="contactPhone">
        <el-input
          v-model="releaseForm.contactPhone"
          :placeholder="autoPrefix('contactPhone', 'pla')"
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('email')" prop="email">
        <el-input
          v-model="releaseForm.email"
          :placeholder="autoPrefix('email', 'pla')"
        />
      </el-form-item>
      <el-form-item
        v-if="releaseForm.cases.length < 3"
        :label="autoPrefix('cases')"
      >
        <el-button class="addButton" @click="changeDialogVisible(true)">
          + {{ autoPrefix('cases', 'pla') }}
        </el-button>
        <span class="maxTip">{{ $t('935e78f') }}</span>
      </el-form-item>

      <el-form-item v-if="releaseForm.cases.length >= 0">
        <div
          v-for="(item, index) of releaseForm.cases"
          :key="index"
          class="caseContainer"
        >
          <div class="item">
            <div class="title">{{ $t('案例标题') }}：</div>
            <div class="des1">{{ item.title }}</div>
          </div>
          <div class="item">
            <div class="title">{{ $t('案例内容') }}：</div>
            <div class="des2">{{ item.description }}</div>
          </div>
          <div class="caseHover">
            <div class="deleteIcon" @click="deleteCase(index)"></div>
            <div class="editIcon" @click="editCase(item, index)"></div>
          </div>
        </div>
      </el-form-item>

      <el-form-item class="buttonCntainer">
        <el-button class="cancel" @click="cancelSubmitForm('releaseForm')">
          {{ $t('6b0feee') }}
        </el-button>
        <el-button class="release" @click="submitForm('releaseForm')">
          {{ submitFormText }}
        </el-button>
      </el-form-item>

      <el-form-item class="warning">
        {{ $t('37bbf16') }}！
      </el-form-item>
    </el-form>
    <DialogForm
      v-if="dialogVisible"
      :dialog-visible="dialogVisible"
      :dialog-form="dialogForm"
      @changeDialogVisible="changeDialogVisible"
      @addCase="addCase"
    />
  </div>
</template>

<script>
import API from '@/api'
import DialogForm from './components/DialogForm'
// import Title from '@/components/Title'
import { mapGetters, mapActions } from 'vuex'
// const i18nPrefix = {
//   label: 'account.register.releaseForm.lable.',
//   pla: 'account.register.releaseForm.pla.',
// }
import validator from '@/utils/validation'
import { getUserInfo } from '@/utils/auth'
export default {
  name: 'ReleaseDesk',
  components: {
    DialogForm,
    // Title,
  },
  props: {
    editId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      hover: false,
      dialogForm: {
        title: '',
        description: '',
        index: '',
      },
      fileList: [],
      label: {
        title: '9c1b456',
        content: '9fc5997',
        country: '1317e70',
        province: '',
        businessTypeId: 'f2752de',
        contactPerson: '8630098',
        contactPhone: 'db8d921',
        email: '5bfb52e',
        cases: '4c06e2f',
      },
      pla: {
        title: 'ba48e69',
        content: '70598c8',
        country: 'b500880',
        province: '328d61d',
        businessTypeId: '6fd5fc3',
        contactPerson: '97ea28f',
        contactPhone: '18b2b78',
        email: '133bec8',
        cases: '4c06e2f',
      },
      releaseForm: {
        title: '',
        content: '',
        country: '',
        province: '',
        businessTypeId: [],
        contactPerson: '',
        contactPhone: '',
        email: '',
        cases: [],
      },

      businessTypeList: [],
    }
  },
  computed: {
    ...mapGetters(['dictionaryList', 'provinceList', 'deskDetailsData']),
    rules() {
      return {
        title: [
          { required: true, message: this.$t('ba48e69') },
        // { validator: validateMobilePhone },
        ],
        content: [
          { required: true, message: this.$t('70598c8') },
        // { validator: validateMobilePhone },
        ],
        country: [{ required: true, message: this.$t('b500880') }],
        province: [{ required: true, message: this.$t('328d61d') }],
        businessTypeId: [
          { required: true, message: this.$t('6fd5fc3') },
        // { validator: lenIs6 },
        ],
        contactPerson: [
          { required: true, message: this.$t('97ea28f') },
        // { validator: lenIs6 },
        ],
        contactPhone: [
          { required: true, message: this.$t('18b2b78') },
          { validator: validator.phone_zh },
        ],
        email: [
          { required: true, message: this.$t('133bec8') },
          { validator: validator.email },
        ],
      }
    },
    submitFormText() {
      let result = this.$t('284d1ae')
      if (this.editId >= 0) {
        result = this.$t('d1a4ef7')
      }
      return result
    },
  },
  watch: {
    dialogVisible(val) {
      if (val === false) {
        this.dialogForm = {
          title: '',
          description: '',
          index: '',
        }
      }
    },
    deskDetailsData(newVal) {
      if (newVal) {
        let businessTypeId = newVal.businessTypeId ? newVal.businessTypeId.split(',') : []
        businessTypeId = businessTypeId.map(val => Number(val))
        this.releaseForm = {
          title: newVal.title,
          content: newVal.content,
          country: newVal.countryId,
          province: newVal.provinceId,
          businessTypeId: businessTypeId,
          contactPerson: newVal.contactPerson,
          contactPhone: newVal.contactPhone,
          email: newVal.email,
          cases: newVal.cases,
        }
      }
    },
    editId(newVal) {
      console.log('editId0editId0editId0', newVal)
      if (newVal) {
        console.log('editId', newVal)
        this.getData()
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions([
      'getDictionaryList',
      'getProvinceList',
      'getLawServiceDetails',
    ]),
    editData() {
      if (this.editId && this.editId >= 0) {
        this.getLawServiceDetails({
          id: this.editId,
        })
      }
    },
    getData() {
      this.getDictionaryList({
        type: 'COUNTRY',
      })
      this.getProvinceList(0)
      this.getBusinessTypeList()
      this.editData()
    },
    deleteCase(index) {
      this.releaseForm.cases.splice(index, 1)
    },
    editCase(item, index) {
      this.dialogForm = item
      this.dialogForm.index = index
      this.changeDialogVisible(true)
    },
    addCase() {
      const obj = {
        title: this.dialogForm.title,
        description: this.dialogForm.description,
      }
      const index = this.dialogForm.index
      if (typeof index === 'number') {
        this.releaseForm.cases.splice(index, 1, obj)
        this.dialogForm.index = ''
      } else {
        this.releaseForm.cases.push(obj)
      }

      this.changeDialogVisible(false)
    },
    changeDialogVisible(val) {
      this.dialogVisible = val
    },

    getBusinessTypeList() {
      const userType = getUserInfo().userType
      const obj = {
        ENTERPRISE: 'OTHER_BUSINESS_TYPE',
        PERSON: 'OTHER_BUSINESS_TYPE',
        LAWYER: 'BUSINESS_TYPE',
        LAW_OFFICE: 'BUSINESS_TYPE',
        TRANSLATE: 'TRANSLATION_BUSINESS_TYPE',
        OTHER: 'OTHER_BUSINESS_TYPE',
      }

      API.common
        .getDictionaryList({
          type: obj[userType],
        })
        .then((res) => {
          this.businessTypeList = res.content
        })
    },
    cancelSubmitForm() {
      if (this.editId >= 0) {
        this.$emit('editCancel')
      } else {
        this.$router.go(-1)
      }
    },
    submitForm() {
      this.$refs.releaseForm.validate((valid) => {
        if (valid) {
          if (this.editId >= 0) {
            this.editLawService()
          } else {
            this.addLawService()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFormData() {
      const form = this.releaseForm
      let businessTypeId = form.businessTypeId
      businessTypeId = Array.isArray(businessTypeId) ? businessTypeId.join() : ''
      return {
        ...form,
        businessTypeId,
      }
    },
    addLawService() {
      const form = this.getFormData()
      API.service.addLawService(form).then(
        (res) => {
          this.handleRes(res)
        },
        (error) => {
          console.log('error', error)
        }
      )
    },
    editLawService() {
      const form = this.getFormData()
      API.service.editLawService(this.editId, form).then(
        (res) => {
          this.handleEditRes(res)
        },
        (error) => {
          console.log('error', error)
        }
      )
    },
    handleRes(res) {
      this.$message({
        message: res.message,
        type: 'success',
      })
      this.$router.push('/service/desk')
    },
    handleEditRes(res) {
      this.$message({
        message: res.message,
        type: 'success',
      })
      this.$emit('editSuccess')
    },
    autoPrefix(name, key = 'label') {
      let str = this[key][name]
      str = this.$t(str)
      if (key === 'label') {
        str += '：'
      }
      return str
    },
    changePage(page) {
      console.log('page', page)
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.releaseDesk {
  .releaseForm {
    width: 600px;
    margin: 0 auto;
    padding: 40px 0;
    .maxTip {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #f5222d;
    }
    .caseContainer {
      position: relative;
      box-sizing: border-box;
      width: 360px;
      // height: 252px;
      margin-bottom: 12px;
      padding: 30px 20px;
      background: rgba(0, 164, 255, 0.04);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      .caseHover {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        .deleteIcon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 152px;
          width: 16px;
          height: 16px;
          background: url('~@/assets/service/deleteIcon.png');
          background-size: 16px 16px;
          cursor: pointer;
        }
        .editIcon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 152px;
          width: 16px;
          height: 16px;
          background: url('~@/assets/service/editIcon.png');
          background-size: 16px 16px;
          cursor: pointer;
        }
      }
      &:hover {
        .caseHover {
          display: block;
        }
      }

      .item {
        display: flex;
      }
      .title {
        white-space: nowrap;
        color: #999999;
      }
      .des1 {
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .des2 {
        position: relative;
        top: 8px;
        width: 250px;
        line-height: 24px;
        word-wrap: break-word;
        word-break: normal;
      }
    }
    & /deep/ .el-form-item {
      margin-bottom: 24px;
    }
    .contentInput /deep/ .el-input__count {
      line-height: initial;
    }
    .uploadenclosure /deep/ .addButtom {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    & /deep/ .el-cascader,
    .el-select,
    .el-date-editor {
      width: 100%;
    }
    .warning /deep/ .el-form-item__content {
      font-size: 12px;
      font-weight: 400;
      color: #f5222d;
      text-align: center;
    }
    .countryContainer {
      display: flex;
      & /deep/ .el-select {
        width: 180px;
      }
    }
    .buttonCntainer {
      width: 100%;
      & /deep/ .el-form-item__content {
        display: flex;
        justify-content: space-between;
      }

      .cancel {
        width: 160px;
      }
      .release {
        width: 160px;
      }
    }
  }
  .dialogCase {
    & /deep/ .el-dialog__header {
      .uni-dialog__header;
    }
    & /deep/ .el-dialog {
      width: 488px;
    }
    & /deep/ .el-dialog__body {
      .uni-dialog__body;
    }
  }
}
</style>
