<template>
  <el-dialog
    class="dialogCase"
    :title="dialogTtile"
    :visible.sync="$parent.dialogVisible"
    append-to-body
  >
    <el-form
      ref="dialogForm"
      :model="dialogForm"
      :rules="rules"
      class="dialogForm"
      label-width="auto"
    >
      <el-form-item :label="autoPrefix('title')" prop="title">
        <el-input
          v-model="dialogForm.title"
          type="textarea"
          maxlength="150"
          :rows="3"
          :placeholder="autoPrefix('title', 'pla')"
          show-word-limit
        />
      </el-form-item>
      <el-form-item :label="autoPrefix('description')" prop="description">
        <el-input
          v-model="dialogForm.description"
          type="textarea"
          maxlength="2000"
          :rows="7"
          :placeholder="autoPrefix('description', 'pla')"
          show-word-limit
        />
      </el-form-item>
      <el-form-item class="buttonCntainer">
        <el-button class="release" @click="submitForm()"> {{ $t("d1a4ef7") }} </el-button>
        <el-button class="cancel" @click="cancelSubmitForm()">
          {{ $t("6b0feee") }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
// const i18nPrefix = {
//   label: 'account.register.dialogForm.lable.',
//   pla: 'account.register.dialogForm.pla.',
// }
export default {
  name: 'DialogForm',
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    dialogForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogTtile: this.$t("4c06e2f"),
      label: {
        title: this.$t("b079c51"),
        description: this.$t("8172cc9"),
      },
      pla: {
        title: this.$t("cade70a"),
        description: this.$t("b8dc38c"),
      },
      rules: {
        title: [{ required: true, message: this.$t("cade70a") }],
        description: [{ required: true, message: this.$t("b8dc38c") }],
      },
    }
  },
  computed: {},
  methods: {
    cancelSubmitForm() {
      this.$emit('changeDialogVisible', false)
    },
    submitForm() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.addCase()
          this.resetForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addCase() {
      this.$emit('addCase')
    },
    resetForm() {
      this.$refs.dialogForm.resetFields()
    },
    autoPrefix(name, key = 'label') {
      // const str = i18nPrefix[key] + name
      // return this.$t(str)
      let str = this[key][name]
      if (key === 'label') {
        str += '：'
      }
      return str
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.dialogCase {
  & /deep/ .el-dialog__header {
    .uni-dialog__header;
  }
  & /deep/ .el-dialog {
    width: 488px;
  }
  & /deep/ .el-dialog__body {
    .uni-dialog__body;
  }
}
</style>
